/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

  createHamburgerMenu();
  createAccordion();
  createPageTop();
  createTab();
  // $('[data-js="js-matchHeight"]').matchHeight();


//  $(window).on('load', function() {

//    createAnchorScroll();
    clickAnchorScroll();

//  });

});

function clickAnchorScroll () {

  $('a[href^="#"]:not([class*="modal"])').on('click', function () {
      if( $(this).closest('[data-js="tab"]').length > 0 ) { return false; }

      var speed = 1000;
      var hh = $(".l-header__inner").innerHeight();
      var href= $(this).attr("href");
      var $target = $(href == "#" || href == "" ? 'html' : href);

      if ($target.length) {
        var pos = $target.offset().top - hh;
        $("html, body").animate({scrollTop:pos}, speed, "swing");
      }

      return false;
  });

}
function createAccordion () {
  $('[data-js="accordion"] .c-accordion__title').on('click', function () {
    $(this).toggleClass('open');
    $('+.c-accordion__body',this).slideToggle();
  });
}

//  pageTop scroll
function createPageTop () {
  var $pagetop = $('a[data-js="pagetop"]');
  if ( $(window).width() > 768 ) $pagetop.hide();
  window.addEventListener('scroll', function() {
    if ( $(window).scrollTop() > 200 ) {
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

  $pagetop.on('click', function (e) {
    e.preventDefault();
    $('html,body').animate({scrollTop:0},1000);
    return false;
  });
}

// Tab
function createTab () {
  var t_item = $('[data-js="tab"] .c-tab__select .c-tab__item > a:not([target="_blank"])');
  var t_article = $('[data-js="tab"] .c-tab__body .c-tab__article');

  t_item.on('click', function (e) {
    e.preventDefault();
    var t_target = $(this).attr('href');
    //active付与
    $(this).parent().siblings().removeClass('is-active');
    $(this).parent().addClass('is-active');
    //show付与
    $(t_target).siblings().removeClass('is-show')
    $(t_target).addClass('is-show');
  });

  // hash付きで遷移した場合、対象のタブを表示
  var hash = location.hash;
  var t = $('[data-js="tab"] .c-tab__select .c-tab__item > a[href="'+ hash +'"]');
  var hash_target = $(hash);
  if ( hash.length ) {
    t.parent().siblings().removeClass('is-active');
    t.parent().addClass('is-active');
    hash_target.siblings().removeClass('is-show');
    hash_target.addClass('is-show');
  }

  $(window).on('resize', function(){
    //横にスライドできます表記出現条件
    $('[data-js="tab"]').each(function () {
      var tab_item = $(this).find('.c-tab__item');
      var totalTabWidth = 0;
      tab_item.each(function() {
        totalTabWidth += tab_item.outerWidth();
      })
      if ( totalTabWidth >= $(window).width() ) {
        $(this).addClass('add_attention');
      } else {
        $(this).removeClass('add_attention');
      }
    });
  });
}
// JavaScript Document

/*--------------------------------------------------

	よくあるお悩みJS

//--------------------------------------------------*/


var getQueryString = function(option) {
  var delimiter;
  if (!option || !option.hasOwnProperty('delimiter')) {
      delimiter = '&';
  } else {
      delimiter = option.delimiter;
  }
  var slice_point = window.location.href.indexOf('?');
  if (slice_point < 0) {
      return null;
  }
  var url_params = window.location.href.slice(slice_point + 1).split(delimiter);
  var query_strings = {};
  for(var i in url_params) {
      var query_string = url_params[i].split('=');
      query_strings[query_string[0]] = query_string[1];
  }
  return query_strings;
};



$(function(){
var query = getQueryString();
function anylink(){
  // URLを取得して「?]で分割「&」でも分割

  var setList = $('.listCover'),
  setItem = $('.'+query['qa']),
  setReplace = $('.selfRep'),
  listBaseWidth = 230,
  thumbOpacity = 1,
  expandHeight = 330,
  expandFadeTime = 300,
  expandEasing = 'linear',
  switchFadeTime = 1000,
  switchEasing = 'linear';

  setList.each(function(){
    var targetObj = $(this);
    var findItem = targetObj.find(setItem),
    findElm = targetObj.find(setReplace);
    var setExpand = $('.expandField'),
    findExpand = targetObj.find(setExpand),
    thisElm = setItem.find(setReplace).html();
    setItem.addClass('active');
    // 展開ボックス制御
    setItem.after('<li class="expandField">' + thisElm + '</li>').next().css({height:'0', opacity:'0'}).stop().animate({height:expandHeight, opacity:'1'},expandFadeTime,expandEasing).append('<span class="btnPrev"></span><span class="btnNext"></span><span class="btnClose"></span>');
    });
    var btnClose = $(".btnClose");
    function switchHide(){
      closeExpand = $('.expandField');
      closeExpand.stop().animate({height:'0',opacity:'0'},expandFadeTime,expandEasing,function(){
        closeExpand.remove();
      });
      setItem.removeClass('active');
    }
    btnClose.click(function(){switchHide();});


    // スクロール位置調整
    var block_qa = $(".firstList img").height();
    // alert(block_qa);
    var thisOffset = setItem.find('img').offset();
    $('html,body').animate({scrollTop:(thisOffset.top - block_qa - 10)},200,'linear');
  }

  if(query && 'qa' in query){
    anylink();
  }
});



$(function(){
//よくあるお悩み項目開閉動作
var setList = $('.listCover'),
setItem = $('.listItem'),
setReplace = $('.selfRep'),
listBaseWidth = 230,
thumbOpacity = 1,
expandHeight = 330,
expandFadeTime = 300,
expandEasing = 'linear',
switchFadeTime = 1000,
switchEasing = 'linear';

setList.each(function(){
  var targetObj = $(this);

  var findItem = targetObj.find(setItem),
  findElm = targetObj.find(setReplace);

  // リストアイテムクリック
  findItem.click(function(){
    if($(this).hasClass('active')){
      closeExpandActive = targetObj.find('.expandField');
      closeExpandActive.stop().animate({height:'0',opacity:'0'},expandFadeTime,expandEasing,function(){
        closeExpandActive.remove();
      });
      findItem.removeClass('active');
    } else {
      var setExpand = $('.expandField'),
      findExpand = targetObj.find(setExpand),
      thisElm = $(this).find(setReplace).html();

      // 展開ボックス制御
      if(0 < findExpand.size()){
        findExpand.empty().html(thisElm);
        $(this).after(findExpand).next().append('<span class="btnPrev"></span><span class="btnNext"></span><span class="btnClose"></span>');
        var setReplaceInner = $('.selfRepInner'),
        findInner = targetObj.find(setReplaceInner);
        findInner.css({opacity:'0'}).stop().animate({opacity:'1'},switchFadeTime,switchEasing);
      } else {
        $(this).after('<li class="expandField">' + thisElm + '</li>').next().css({height:'0', opacity:'0'}).stop().animate({height:expandHeight, opacity:'1'},expandFadeTime,expandEasing).append('<span class="btnPrev"></span><span class="btnNext"></span><span class="btnClose"></span>');
      }

      // スクロール位置調整
      var thisOffset = $(this).find('img').offset();
      $('html,body').animate({scrollTop:(thisOffset.top+0)},200,'linear');

      // 操作ボタン動作
      function switchNext(){
        var setActiveN = targetObj.find('.active');
        setActiveN.each(function(){
          var listLenghN = findItem.length,
          listIndexN = findItem.index(this),
          listCountN = listIndexN+1,
          findItemFirst = findItem.first();

          if(listLenghN == listCountN){
            findItemFirst.click();
          } else {
            $(this).next().next().click();
          }
        });
      }
      function switchPrev(){
        var setActiveP = targetObj.find('.active');
        setActiveP.each(function(){
          var listLenghP = findItem.length,
          listIndexP = findItem.index(this),
          listCountP = listIndexP+1,
          findItemLast = findItem.last();

          if(1 == listCountP){
            findItemLast.click();
          } else {
            $(this).prev().click();
          }
        });
      }
      function switchHide(){
        closeExpand = targetObj.find('.expandField');
        closeExpand.stop().animate({height:'0',opacity:'0'},expandFadeTime,expandEasing,function(){
          closeExpand.remove();
        });
        findItem.removeClass('active');
      }

      $(this).addClass('active').siblings().removeClass('active');

      var btnPrev = targetObj.find('.btnPrev'),btnNext = targetObj.find('.btnNext'),btnClose = targetObj.find('.btnClose');
      btnPrev.click(function(){switchPrev();});
      btnNext.click(function(){switchNext();});
      btnClose.click(function(){switchHide();});

    }
  });

  // サムネイルロールオーバー
  var agent = navigator.userAgent;
  if(!(agent.search(/iPhone/) != -1 || agent.search(/iPad/) != -1 || agent.search(/iPod/) != -1 || agent.search(/Android/) != -1)){
    findItem.hover(function(){
      $(this).stop().animate({opacity:thumbOpacity},200);
    },function(){
      $(this).stop().animate({opacity:'1'},200);
    });
  }

  // リキッド操作
  function listAdjust(){
    var ulWrap = targetObj.width(),
    ulNum = Math.floor(ulWrap / listBaseWidth),
    liFixed = Math.floor(ulWrap / ulNum);
    findItem.css({width: (liFixed)});
  }
  $(window).resize(function(){listAdjust();}).resize();
  $(window).load(function(){setTimeout(function(){listAdjust();},200);}); // for IE8
});
});


/*-----------------------------------------------------
よくある質問ブロックサイズをそろえる
-----------------------------------------------------*/
function qaPageBoxsize(){
	var qaBox = $(".qaContainer li").width();
	$(".qaContainer li").css('height',qaBox +'px');
}

window.onload = function(){
	qaPageBoxsize();
}

$(window).resize(function(){
	qaPageBoxsize();
});


$(function(){
  $('.slider').slick({
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // prevArrow: '<div class="slick-prev"><img src="inc/image/common/prev-arrow.png"></div>',
    // nextArrow: '<div class="slick-next"><img src="inc/image/common/next-arrow.png"></div>',
    centerMode: true,
    variableWidth: true,
    dots: true,
    centerPadding: '20%',
    responsive: [
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 2,
          arrows: false,
        }
      }]
  });

});
